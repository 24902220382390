/** @jsx jsx */
import { jsx, Box, Grid, Container, Heading, Text } from "theme-ui"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import articleTypography from "../styles/article-typography"
import parse from "html-react-parser"
import { replaceCode } from "../components/PostCode"
import SEO from "../components/seo"
import Button from "../components/Button"

const PageTemplate = ({ data: { page } }) => {
  const fields = {
    title: page.title,
    excerpt: page.excerpt,
    featuredImage: {
      data: getImage(page.featuredImage?.node?.localFile),
      alt: page.featuredImage?.node?.altText || page.title || ``,
    },
  }

  return (
    <Layout>
      <SEO yoastSeo={page.seo} />
      <article>
        <Box sx={{ bg: "background.dark2x", mb: 10 }}>
          <Container py={10}>
            <Heading as="h1" variant="xxl" sx={{ textAlign: "center" }}>
              {fields.title}
            </Heading>
            <Text as="p" variant="md" sx={{ textAlign: "center", mt: 4 }}>
              Fill out the form below to share your story!
            </Text>
            <div sx={{ mt: 4, textAlign: "center" }}>
              <Button
                as="external_link"
                url="https://docs.google.com/forms/d/e/1FAIpQLSeC17kZToEk_8Z80SuVIsu9kc6P3yH_oo22AA5Q6nxU72XKCA/viewform"
                rel="noopener noreferrer"
                target="_blank"
                variant="primary"
                arrow
              >
                Contribute
              </Button>
            </div>
          </Container>
        </Box>
        {!!page.content && (
          <Box as="section" mb={10}>
            <Grid
              sx={{
                gridGap: 0,
                gridTemplateColumns:
                  "[full-start] minmax(25px,auto) [wide-start] minmax(auto,207.5px) [main-start] calc(min(730px, 100% - 50px)) [main-end] minmax(auto,207.5px) [wide-end] minmax(25px,auto) [full-end]",
                "& > *": {
                  gridColumn: "main-start/main-end",
                },
                ...articleTypography,
              }}
            >
              {parse(page.content, {
                replace: replaceCode,
              })}
            </Grid>
          </Box>
        )}
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query {
    page: wpPage(slug: { eq: "contribute" }) {
      ...WpPageFields
    }
  }
`
